import Swiper from 'swiper';
$(function () {
	var swiper = new Swiper('.swiper-container', {
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
	var appear = false;
	var pagetop = $('.pagetop');
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {  //100pxスクロールしたら
			if (appear == false) {
				appear = true;
				pagetop.stop().animate({
					'bottom': '20px' //下から20pxの位置に
				}, 300); //0.3秒かけて現れる
			}
		} else {
			if (appear) {
				appear = false;
				pagetop.stop().animate({
					'bottom': '-100px' //下から-20pxの位置に
				}, 300); //0.3秒かけて隠れる
			}
		}
	});
	pagetop.click(function () {
		$('body, html').animate({ scrollTop: 0 }, 500); //0.5秒かけてトップへ戻る
		return false;
	});
});


$(document).on('click', '.header__navopen', function () {
	$(".header__navopen,.nav").toggleClass('is-active');
})

$(document).on('click', '.navclose .btn', function () {
	$(".header__navopen,.nav").toggleClass('is-active');
})

$(document).on('click', '.housepage__facility__list .btn', function () {
	$(".housepage__facility__list .popup").removeClass('is-active');
	$(this).next(".popup").toggleClass('is-active');
	return false;
})
$(document).on('click', '.housepage__facility__list .close', function () {
	$(".housepage__facility__list .popup").removeClass('is-active');
	return false;
})
