const moment = require('moment');

import vegas from 'vegas/dist/vegas.js'
/**
	* 単純なサンプル
	* moment.jsを使って現在日付をconsole出力するだけ
	*/
$(function () {
	$(".toppage__mainimage__slide").vegas({
		slides: [
			{ src: "/assets/img/top/slide/photo1.jpg" },
			{ src: "/assets/img/top/slide/photo2.jpg" },
			{ src: "/assets/img/top/slide/photo3.jpg" }
		]
	});
	setTimeout(() => {
		$(".loader").fadeOut(500, function () {
			$(".loader").remove()
			$(".toppage__mainimage__line").addClass("is-active");
		});
	}, 500);
});

$(window).on('load', function () {
	$.ajax({
		type: 'GET',
		url: '/instagram.php',
		dataType: 'json',
		success: function (json) {
			var j = json['business_discovery']['media']['data'];
			for (var i = 0; i < 3; i++) {
				$('#instagram').append('<div class="toppage__instagram__item" style="background:url(' + j[i]['media_url'] + ')"><a href="' + j[i]['permalink'] + '" target="_blank"></a></div>');
			}
		}
	});
})
